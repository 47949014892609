var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"photoTrapCameraList"},[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.cameras),"columns":_vm.kgm_responsiveColumns(),"filterable":false,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.cameras),"sortable":{
      allowUnsort: _vm.kgm_allowUnsort,
      mode: _vm.kgm_sortMode
    },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"kameraIconTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[_c('font-awesome-icon',{attrs:{"icon":"camera"}})],1)]}},{key:"booleanTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.kgm_getNestedValue(props.field, props.dataItem)}})])]}},{key:"dateTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.dateTime_dateTime(_vm.kgm_getNestedValue(props.field, props.dataItem))),expression:"dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem))"}]},[_vm._v(_vm._s(_vm.dateTime_fromNow(_vm.kgm_getNestedValue(props.field, props.dataItem))))])])]}},{key:"optionsTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.$eventBus.$emit('photoTrap_find', props.dataItem.cameraId)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"search"}}),_c('span',[_vm._v("Photos")])],1),_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.$eventBus.$emit('photoTrap_detail', props.dataItem.cameraId)}}},[_c('font-awesome-icon',{staticClass:"gray mr-1",attrs:{"icon":"info-circle"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('details'))+" ")])],1),_c('button',{class:("btn btn-" + (_vm.buttonByState(props.dataItem.state).type) + " btn-sm"),on:{"click":function($event){return _vm.$eventBus.$emit('photoTrap_enable', props.dataItem.cameraId)}}},[_c('span',[_vm._v(_vm._s(_vm.buttonByState(props.dataItem.state).text))])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }