<template>
  <div class="photoTrapCameraList">
    <Grid
      :ref="kgm_ref"
      :style="{height: 'auto'}"
      :data-items="kgm_computedGridItems(cameras)"
      :columns="kgm_responsiveColumns()"
      :filterable="false"
      :filter="kgm_filter"
      :pageable="kgm_pagable"
      :page-size="kgm_take"
      :skip="kgm_skip"
      :take="kgm_take"
      :total="kgm_allGridItems(cameras)"
      :sortable="{
        allowUnsort: kgm_allowUnsort,
        mode: kgm_sortMode
      }"
      :sort="kgm_sort"
      selected-field="selected"
      @rowclick="kgm_gridOnRowClick"
      @filterchange="kgm_gridFilterChange"
      @pagechange="kgm_gridPageChange"
      @sortchange="kgm_gridSortChange"
    >
      <template
        slot="kameraIconTemplate"
        slot-scope="{props}"
      >
        <td :class="props.className">
          <font-awesome-icon
            icon="camera"
          />
        </td>
      </template>
      <template
        slot="booleanTemplate"
        slot-scope="{props}"
      >
        <td :class="props.className">
          <input
            :checked="kgm_getNestedValue(props.field, props.dataItem)"
            type="checkbox"
          >
        </td>
      </template>
      <template
        slot="dateTemplate"
        slot-scope="{props}"
      >
        <td :class="props.className">
          <span v-tooltip="dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem))">{{ dateTime_fromNow(kgm_getNestedValue(props.field, props.dataItem)) }}</span>
        </td>
      </template>
      <template
        slot="optionsTemplate"
        slot-scope="{props}"
      >
        <td :class="props.className">
          <div class="btn-group">
            <button
              class="btn btn-primary btn-sm"
              @click="$eventBus.$emit('photoTrap_find', props.dataItem.cameraId)"
            >
              <font-awesome-icon
                class="mr-2"
                icon="search"
              />
              <span>Photos</span>
            </button>
            <button
              class="btn btn-primary btn-sm"
              @click="$eventBus.$emit('photoTrap_detail', props.dataItem.cameraId)"
            >
              <font-awesome-icon
                class="gray mr-1"
                icon="info-circle"
              />
              <span>
                {{ $t('details') }}
              </span>
            </button>
            <button
              :class="`btn btn-${buttonByState(props.dataItem.state).type} btn-sm`"
              @click="$eventBus.$emit('photoTrap_enable', props.dataItem.cameraId)"
            >
              <span>{{ buttonByState(props.dataItem.state).text }}</span>
            </button>
          </div>
        </td>
      </template>
    </Grid>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "PhotoTrapCameraList",
  mixins: [
    dateTimeMixin,
    kendoGridMixin
  ],
  props: {
    cameras: {
      type: Array,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      kgm_sort: [
        {
          "field": "actionCamId",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          title: '',
          filterable: false,
          width: '50px',
          cell: 'kameraIconTemplate'
        },
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Cam Lane Name'
        },
        {
          field: 'actionCamId',
          filterable: false,
          filter: 'text',
          title: 'Cam Lane ID'
        },
        {
          field: 'cameraId',
          filterable: false,
          filter: 'text',
          title: 'Spot ID'
        },
        {
          field: 'photoCount',
          filterable: true,
          filter: 'numeric',
          title: 'Captured',
          width:'90px',
          hideOn: ['smDown']
        },
        {
          field: 'version',
          filterable: true,
          filter: 'numeric',
          title: 'Version',
          width:'150px',
          hideOn: ['mdDown']
        },
        {
          field: 'lastSuccessfullContact',
          filterable: true,
          filter: 'date',
          title: 'Last Contact',
          width:'125px',
          cell: 'dateTemplate',
          hideOn: ['lgDown']
        },
        {
          field: 'latency',
          filterable: true,
          filter: 'numeric',
          title: 'Latency',
          width:'75px',
          hideOn: ['xlDown']
        },
        {
          field: 'stateInSync',
          filterable: true,
          filter: 'boolean',
          title: 'In Sync',
          width:'75px',
          cell: 'booleanTemplate',
          hideOn: ['xlDown']
        },
        {
          title: 'Options',
          filterable: false,
          cell: 'optionsTemplate',
          width: '235px'
        }
      ]
    }
  },
  methods: {
    buttonByState (state) {
      if (state === "enabled") {
        return {
          text: "Disable",
          type: "warning"
        };
      }
      return {
        text: "Enable",
        type: "success"
      };
    }
  }
}
</script>
